/**
 * Logs a message to the console if the logger is enabled.
 *
 * @module console
 * @param message
 * @param objects
 * @public
 * @returns {void}
 */
export const log = (message, ...objects) => {

	if (!isLoggerEnabled()) return

	if (objects.length) {
		console.log("Pixel Manager: " + message, ...objects)
	} else {
		console.log("Pixel Manager: " + message)
	}
}

/**
 * Logs an error message to the console.
 *
 * @module console
 * @param message
 * @param objects
 * @public
 * @returns {void}
 */
export const error = (message, ...objects) => {

	// if (!isLoggerEnabled()) return

	if (objects.length) {
		console.error("Pixel Manager: " + message, ...objects)
	} else {
		console.error("Pixel Manager: " + message)
	}
}

/**
 * Checks if the logger is enabled.
 *
 * @return {boolean}
 */
const isLoggerEnabled = () => {

	if (urlLoggerOff()) return false

	if (sessionLoggerEnabled()) return true

	if (urlLoggerOn()) return true

	return !!wpmDataLayer?.general?.logger?.is_active
}

/**
 * Turn off the logger if the URL parameter is set.
 *
 * @return {boolean}
 */
const urlLoggerOff = () => {

	if (wpm.urlHasParameter("pmwloggeroff")) {
		wpm.storeData("loggerEnabled", false)
	}

	return wpm.urlHasParameter("pmwloggeroff")
}

/**
 * Check if the logger is enabled for the session.
 *
 * @return {boolean}
 */
const sessionLoggerEnabled = () => {
	return wpm.retrieveData("loggerEnabled")
}

/**
 * Turn on the logger if the URL parameter is set.
 *
 * @return {boolean}
 */
const urlLoggerOn = () => {

	if (wpm.urlHasParameter("pmwloggeron")) {
		wpm.storeData("loggerEnabled", true)
	}

	return wpm.urlHasParameter("pmwloggeron")
}
